<template>
  <el-card class="iot-person public-page-style iot-search-header">
    <div slot="header">
      <el-form :inline="true" :model="getForm">
        <el-form-item>
          <el-input
            size="small"
            clearable
            v-model="getForm.interfaceName"
            placeholder="请输入要查询的接口名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="checkTable()"
            >查询</el-button
          >
        </el-form-item>
        <el-button
          type="primary"
          size="mini"
          class="iot-Interface-create"
          @click="addcommunicationProtocol()"
          >新增接口</el-button
        >
      </el-form>
    </div>
    <div style="height:678px;overflow-y: auto;">
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
    >
      <el-table-column prop="interfaceId" label="ID"></el-table-column>
      <el-table-column prop="interfaceName" label="接口名称"></el-table-column>
      <el-table-column prop="interfacePath" label="接口路径"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="warning"
            @click="edit(scope.row.interfaceId)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="del(scope.row.interfaceId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
      
    </el-table>
    </div>
    <div class="page">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
        :total="total"
        :page-size="getForm.size"
        layout="total, sizes, prev, pager, next, jumper"
        @prev-click="
          () => {
            $set(getForm, 'current', getForm.current - 1);
            getList();
          }
        "
        @next-click="
          () => {
            $set(getForm, 'current', getForm.current + 1);
            getList();
          }
        "
      >
      </el-pagination>
    </div>
    <el-dialog
    top="3%"
      title="填写表单"
      :show-close="false"
      :visible.sync="dataConditionVisinbile"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="dataInterfaceForm"
        ref="subRef"
        :rules="rules"
        label-position="top"
      >
        <el-form-item label="接口名称" label-width="120" prop="interfaceName">
          <el-input
            v-model="dataInterfaceForm.interfaceName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="接口路径" label-width="120" prop="interfacePath">
          <el-input
            v-model="dataInterfaceForm.interfacePath"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="dataConditionVisinbile = false"
          >取 消</el-button
        >
        
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
let that;
export default {
  name: "Interface",
  props: {
    msg: String,
  },
  data() {
    return {
      theme: { TABLE_HEADER_COLOR },
      tableData: [],
      getForm: {
        current: 1,
        size: 10,
        interfaceName: "",
      },
      total: 0,
      dataConditionVisinbile: false,
      dataInterfaceForm: {
        interfaceId: "",
        interfaceName: "",
        interfacePath: "",
      },
      isCreate: true,
      rules: {
        interfaceName: [
          { required: true, message: "请输入接口名称", trigger: "blur" },
        ],
        interfacePath: [
          { required: true, message: "请输入接口路径", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
     handleSizeChange(val) {
        that.getForm.size=val;
        that.getList();
      },
      handleCurrentChange(val) {
        that.getForm.current=val;
        that.getList();
      },
    getList: function () {
      that.$api.Interface
        .getInterfacePage(that.getForm)
        .then((response) => {
       
          that.tableData = response.data.records;
          that.total = response.data.total;
        });
    },
    handleClose: function () {
      that.dataConditionVisinbile = false;
    },
    addcommunicationProtocol: function () {
      that.dataInterfaceForm= {
        interfaceId: "",
        interfaceName: "",
        interfacePath: "",
      },
      that.dataConditionVisinbile = true;
      that.isCreate = true;
    },
    edit: function (id) {
      that.$api.Interface
        .getInterface(id)
        .then((response) => {
          
          that.dataConditionVisinbile = true;
          that.isCreate = false;
          that.dataInterfaceForm = response.data;
        });
    },
    del: function (id) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.Interface
            .delInterface(id)
            .then(() => {
              that.getList();
              that.$message({
                message: "删除成功",
                type: "success",
              });
            });
        })
        .catch(() => {});
    },
    checkTable: function () {
      that.getList();
    },
    submitForm: function () {
      that.$refs["subRef"].validate((valid) => {
        if (valid) {
          let _api = that.isCreate
            ? that.$api.Interface.addInterface(
                that.dataInterfaceForm
              )
            : that.$api.Interface.putInterface(
                that.dataInterfaceForm
              );
          _api.then((response) => {
            if (response.code == 200) {
              that.dataConditionVisinbile = false;
              that.isCreate = true;
              that.getList();
              that.$message({
                type: "success",
                message: "请求成功",
              });
              that.$refs.subRef.resetFields();
            }
          });
        }
      });
    },
  },
  beforeMount: function () {
    that = this;
  },
  mounted: function () {
    that.getList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iot-Interface-create {
  float: right;
  margin-top: 10px;
}
.iot-Interface-permission {
  height: 387px;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}
.iot-person >>> .el-select {
  width: 100%;
}
.page{
  display: flex;
  justify-content: flex-end;

}
</style>
